<template>
  <div class="setting_form clear">
    <form class="basic_form clear">

      <div class="input_field col-md-6">
        <label for="">New Password</label>
        <input v-model="new_password" type="password" >
      </div>
      <div class="input_field col-md-6">
        <label for="">Confirm New Password</label>
        <input v-model="confirm_new_password" type="password" >
      </div>
      <div class="input_field col-md-6">
        <label for="">Current Password</label>
        <input v-model="current_password" type="password" >
      </div>
      <!--<div class="input_field col-md-6">-->
      <!--<label for="">Password</label>-->
      <!--<input v-model="password" type="password">-->
      <!--</div>-->
      <div class="clearfix"></div>
      <div class="input_field col-md-6">
        <button class="btn--cta" @click.prevent="updatePassword()">Update Password</button>
      </div>

    </form>
  </div>
</template>

<script>
import { updatePasswordURL } from '@/config/config.js'
import { mapGetters } from 'vuex'

export default ({
  data () {
    return {
      new_password: null,
      confirm_new_password: null,
      current_password: null
    }
  },
  created () {

  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods: {
    updatePassword () {
      if (!this.new_password) {
        this.alertMessage('Please enter new password.', 'error')
        return
      }

      if (!this.confirm_new_password) {
        this.alertMessage('Please enter confirm new password.', 'error')
        return
      }
      if (this.new_password != this.confirm_new_password) {
        this.alertMessage('Your new password and confirm new password does not match.', 'error')
        return
      }

      if (!this.current_password) {
        this.alertMessage('Please enter new password.', 'error')
        return
      }

      this.$http.post(updatePasswordURL, {
        new_password: this.new_password,
        confirm_new_password: this.confirm_new_password,
        current_password: this.current_password
      }).then(response => {
        if (response.data.tokenError && response.data.tokenError == true) {
          this.alertMessage(response.data.message, 'error')
        } else {
          if (response.data.status) {
            this.alertMessage(response.data.message, 'success')
          } else {
            this.alertMessage(response.data.message, 'error')
          }
        }
      }, response => {

      })
    }
  }

})
</script>
